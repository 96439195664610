<template>
    <div id="downloadWraper">
        <top-search></top-search>
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h1 style="margin-top: 35px;">Vielen Dank!</h1>
                    <p style="margin-bottom: 35px;">Sie können das PDF nun kostenlos herunterladen.</p>
                    <span class="btn-primary btn" style="margin-bottom: 55px;">
                        <a :href="dl_url" target="_blank">Kostenloser Download</a>
                    </span>
                </div>
            </div>
        </div>
        <div class="adsense">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <Adsense v-if="adsense"
                                 data-ad-client="ca-pub-2696232756207990"
                                 data-ad-slot="8851516560">
                        </Adsense>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TopSearch from "../components/TopSearch";
    import Vue from "vue";
    import Ads from 'vue-google-adsense'
    Vue.use(require('vue-script2'))
    Vue.use(Ads.Adsense)
    Vue.use(Ads.InArticleAdsense)
    Vue.use(Ads.InFeedAdsense)
    export default {
        name: 'Download',
        components: { TopSearch},
        data: function() {
            return {
                dl_url: "https://api.pausenhof.de/item/download/"+this.$route.params.key,
                adsense : false
            }
        },
        created() {
            let cookieConsent=  Vue.$cookies.get("rephCookieCons");
            if(cookieConsent == '111') {
                this.adsense = true;
            } else if(cookieConsent == '101') {
                this.adsense = true;
            } else if(cookieConsent == '110') {
                this.adsense = false;
            }
        }
    }
</script>

<style scoped>
    #downloadWraper h2{
        font-family: 'Londrina Shadow';
        color:white;
        font-size: 3.5rem;
    }
    #downloadWraper .btn-primary{
        background:#f76d6d;
        border-color:#f76d6d;
        color:#fff !important;
    }
    #downloadWraper .btn-primary a{
        color:#fff !important;
    }
    #downloadWraper .btn-primary a:hover{
        text-decoration: none !important;
    }

</style>

